import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        },
        {
          path: '/customers',
          name: 'Customer',
          component: () => import('@/view/pages/customers/Customer.vue')
        },
        {
          path: '/new-customers',
          name: 'New-Customer',
          component: () => import('@/view/pages/customers/NewCustomer.vue')
        },
        {
          path: '/tickets',
          name: 'Ticket',
          component: () => import('@/view/pages/tickets/Ticket.vue')
        },
        {
          path: '/commissions',
          name: 'Commission',
          component: () => import('@/view/pages/commissions/Commission.vue')
        }
        // {
        //   path: "/vuetify",
        //   name: "vuetify",
        //   component: () => import("@/view/pages/vuetify/Vuetify.vue"),
        //   children: [
        //     {
        //       path: "alerts",
        //       name: "vuetify-alerts",
        //       component: () => import("@/view/pages/vuetify/Alerts.vue")
        //     },
        //     {
        //       path: "avatars",
        //       name: "vuetify-avatars",
        //       component: () => import("@/view/pages/vuetify/Avatars.vue")
        //     },
        //     {
        //       path: "badges",
        //       name: "vuetify-badges",
        //       component: () => import("@/view/pages/vuetify/Badges.vue")
        //     },
        //     {
        //       path: "buttons",
        //       name: "vuetify-buttons",
        //       component: () => import("@/view/pages/vuetify/Buttons.vue")
        //     },
        //     {
        //       path: "calendars",
        //       name: "vuetify-calendars",
        //       component: () => import("@/view/pages/vuetify/Calendars.vue")
        //     },
        //     {
        //       path: "cards",
        //       name: "vuetify-cards",
        //       component: () => import("@/view/pages/vuetify/Cards.vue")
        //     },
        //     {
        //       path: "chips",
        //       name: "vuetify-chips",
        //       component: () => import("@/view/pages/vuetify/Chips.vue")
        //     },
        //     {
        //       path: "dialog",
        //       name: "vuetify-dialog",
        //       component: () => import("@/view/pages/vuetify/Dialog.vue")
        //     },
        //     {
        //       path: "autocompletes",
        //       name: "vuetify-autocompletes",
        //       component: () =>
        //         import("@/view/pages/vuetify/forms/Autocompletes.vue")
        //     },
        //     {
        //       path: "file-inputs",
        //       name: "vuetify-file-inputs",
        //       component: () =>
        //         import("@/view/pages/vuetify/forms/FileInputs.vue")
        //     },
        //     {
        //       path: "forms",
        //       name: "vuetify-forms",
        //       component: () => import("@/view/pages/vuetify/forms/Forms.vue")
        //     },
        //     {
        //       path: "selection-controls",
        //       name: "vuetify-selection-controls",
        //       component: () =>
        //         import("@/view/pages/vuetify/forms/SelectionControls.vue")
        //     },
        //     {
        //       path: "selects",
        //       name: "vuetify-selects",
        //       component: () => import("@/view/pages/vuetify/forms/Selects.vue")
        //     },
        //     {
        //       path: "textareas",
        //       name: "vuetify-textareas",
        //       component: () =>
        //         import("@/view/pages/vuetify/forms/Textareas.vue")
        //     },
        //     {
        //       path: "text-fields",
        //       name: "vuetify-text-fields",
        //       component: () =>
        //         import("@/view/pages/vuetify/forms/TextFields.vue")
        //     },
        //     {
        //       path: "simple-tables",
        //       name: "vuetify-simple-tables",
        //       component: () =>
        //         import("@/view/pages/vuetify/tables/SimpleTables.vue")
        //     },
        //     {
        //       path: "data-tables",
        //       name: "vuetify-data-tables",
        //       component: () =>
        //         import("@/view/pages/vuetify/tables/DataTables.vue")
        //     },
        //     {
        //       path: "tabs",
        //       name: "vuetify-tabs",
        //       component: () => import("@/view/pages/vuetify/Tabs.vue")
        //     },
        //     {
        //       path: "timelines",
        //       name: "vuetify-timelines",
        //       component: () => import("@/view/pages/vuetify/Timelines.vue")
        //     },
        //     {
        //       path: "tooltips",
        //       name: "vuetify-tooltips",
        //       component: () => import("@/view/pages/vuetify/Tooltips.vue")
        //     },
        //     {
        //       path: "treeview",
        //       name: "vuetify-treeview",
        //       component: () => import("@/view/pages/vuetify/Treeview.vue")
        //     }
        //   ]
        // },
        // {
        //   path: "/wizard",
        //   name: "wizard",
        //   component: () => import("@/view/pages/wizard/Wizard.vue"),
        //   children: [
        //     {
        //       path: "wizard-1",
        //       name: "wizard-1",
        //       component: () => import("@/view/pages/wizard/Wizard-1.vue")
        //     },
        //     {
        //       path: "wizard-2",
        //       name: "wizard-2",
        //       component: () => import("@/view/pages/wizard/Wizard-2.vue")
        //     },
        //     {
        //       path: "wizard-3",
        //       name: "wizard-3",
        //       component: () => import("@/view/pages/wizard/Wizard-3.vue")
        //     },
        //     {
        //       path: "wizard-4",
        //       name: "wizard-4",
        //       component: () => import("@/view/pages/wizard/Wizard-4.vue")
        //     }
        //   ]
        // }
      ]
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue')
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue')
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue')
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue')
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue')
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login')
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/Register')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
});
